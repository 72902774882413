import { ImageWithAltType, LinkType } from '../types';
import cn from '../utils/cn';
import { buttonVariants } from './Button';
import Mask from './Mask';
import LogoSymbol from './icons/LogoSymbol';
import NoPrefetchLink from './NoPrefetchLink';

interface HeroMaskProps {
  overline?: string;
  title: string;
  image?: ImageWithAltType;
  primaryAction?: LinkType;
  secondaryAction?: LinkType;
}

function HeroMask({ overline, title, image, primaryAction, secondaryAction }: HeroMaskProps) {
  if (!title) return null;

  return (
    <section className="bg-primary">
      <div className="max-w-8xl mx-auto grid grid-cols-1 pl-6 pt-10 duration-300 sm:grid-cols-2 sm:pl-10 lg:pl-20 2xl:px-20">
        <div className="flex flex-col justify-center gap-2 pb-6 pr-6 duration-300 sm:pb-10 sm:pr-10 lg:gap-10 lg:pb-20">
          <div className="text-on-primary">
            {overline && (
              <span className="text-tertiary mb-2 inline-block text-xl font-semibold lg:text-3xl">
                {overline}
              </span>
            )}

            <h1 className="mb-5 text-[46px] font-semibold leading-none lg:mt-2 lg:text-6xl">
              {title}
            </h1>
          </div>

          <div className="flex flex-wrap gap-3">
            {primaryAction && primaryAction.linkText && (
              <NoPrefetchLink
                href={primaryAction.href || ''}
                className={buttonVariants({ variant: 'primary' })}
              >
                {primaryAction.linkText}
              </NoPrefetchLink>
            )}
            {primaryAction && secondaryAction && secondaryAction.linkText && (
              <NoPrefetchLink
                href={secondaryAction.href || '/'}
                className={buttonVariants({
                  variant: primaryAction.linkText ? 'secondary-dark' : 'primary',
                })}
              >
                {secondaryAction.linkText}
              </NoPrefetchLink>
            )}
          </div>
        </div>
        {image && (
          <div className={cn(' relative ml-10 place-self-end sm:ml-0')}>
            <Mask image={image} />
            <LogoSymbol className="text-secondary absolute bottom-8 right-8 h-16 w-16 max-lg:hidden" />
          </div>
        )}
      </div>
    </section>
  );
}

export default HeroMask;
